<template>
  <div />
</template>

<script>
import { mapActions } from "vuex";
import store from "@/store";
import Cookies from "js-cookie";

export default {
  created() {
    this.login();
  },
  methods: {
    ...mapActions("loader", ["showLoader"]),

    login() {
      const tokenInit = JSON.stringify(this.$route.query.token);
      try {
        const token = tokenInit.replace(/['"]+/g, ""),
          arrayTokenDecrypt = JSON.parse(window.atob(token.split(".")[1])),
          data = JSON.parse(arrayTokenDecrypt["infoUser"]);

        if (Object.keys(data).length !== 0) {
          //Guardo el Token en los Cookies
          this.$store.dispatch("auth/saveToken", {
            token: token,
          });

          let desTypeUser =
            data["typeUser"] == 1
              ? "Cliente"
              : data["typeUser"] == 2
              ? "Empresa de Transporte"
              : "";

          //Guardo los datos de usuario en LocalStorage
          this.$store.dispatch("auth/saveUser", {
            user: {
              username: data["username"],
              name: data["name"],
              lastName: data["lastName"],
              typeUser: data["typeUser"],
              desTypeUser: desTypeUser,
              profileName: data["profileName"],
              nameCompany: data["nameCompany"],
              idCompany: data["idCompany"],
            },
          });

          this.showLoader({
            show: true,
          });

          this.redirect();
        } else {
          window.location.href = process.env.VUE_APP_URL_LOGIN_SUITE;
          //this.$router.push({ name: "Expired", params: { type: 1 } });
        }
      } catch (e) {
        if (store.getters["auth/token"]) {
          this.redirect();
        } else {
          window.location.href = process.env.VUE_APP_URL_LOGIN_SUITE;
          //this.$router.push({ name: "Expired", params: { type: 1 } });
        }
      }
    },
    redirect() {
      const intendedUrl = Cookies.get("intended_url");
      if (intendedUrl) {
        Cookies.remove("intended_url");
        this.$router.push({ path: intendedUrl });
      } else {
        this.$router.push({ name: "Offers" });
      }
    },
  },
};
</script>
